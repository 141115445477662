import { render, staticRenderFns } from "./TheVasionHome.vue?vue&type=template&id=1be50eee&scoped=true&"
import script from "./TheVasionHome.vue?vue&type=script&lang=js&"
export * from "./TheVasionHome.vue?vue&type=script&lang=js&"
import style0 from "./TheVasionHome.vue?vue&type=style&index=0&id=1be50eee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be50eee",
  null
  
)

export default component.exports