<template>
  <div v-show="inHome" class="container" :class="{ notLoaded: !displayName }">
    <div class="welcome-container">
      <span class="welcome">Welcome, {{ displayName }}.</span>
      <!-- Per Steve and Connor, hiding this for now
      <span class="view-profile">
        <VasionButton
          id="view-profile"
          :classProp="'text'"
        >
          View Profile
        </VasionButton>
      </span>
      -->
    </div>
    <div class="app-container">
      <div
        id="home-app-picker"
        :class="{
          'apps-per-row-2': appAccessCount === 4,
          'apps-per-row-3': appAccessCount === 5 || appAccessCount === 6,
          'apps-per-row-4': appAccessCount === 7 || appAccessCount === 8,
          'apps-per-row-5': appAccessCount <= 3 || appAccessCount > 8
        }"
      >
        <div class="group-row">
          <div
            v-if="showWorkspaceApp"
            id="home-workspace"
            class="app-group"
            @click="changeActiveSubApp('workspace')"
          >
            <VasionHomeWorkspace />
            <label class="app-label">Workspace</label>
          </div>
          <div
            v-if="showReportsApp"
            id="home-reports"
            class="app-group"
            @click="changeActiveSubApp('reports')"
          >
            <VasionHomeReports />
            <label class="app-label">Reports</label>
          </div>
          <div
            v-if="showStorageApp"
            id="home-storage"
            class="app-group"
            @click="changeActiveSubApp('storage')"
          >
            <VasionHomeStorage />
            <label class="app-label">Storage</label>
          </div>
          <div
            v-if="showCaptureApp"
            id="home-capture"
            class="app-group"
            @click="changeActiveSubApp('capture')"
          >
            <VasionHomeCapture />
            <label class="app-label">Capture</label>
          </div>
          <div
            v-if="showSignatureApp"
            id="home-signatures"
            class="app-group"
            @click="changeActiveSubApp('signature')"
          >
            <VasionHomeSignature />
            <label class="app-label">Signature</label>
          </div>
          <div
            v-if="showFormsApp"
            id="home-forms"
            class="app-group"
            @click="changeActiveSubApp('forms')"
          >
            <VasionHomeForms />
            <label class="app-label">Forms</label>
          </div>
          <div
            v-if="showTemplatesApp"
            id="home-templates"
            class="app-group"
            @click="changeActiveSubApp('templates')"
          >
            <VasionHomeTemplates />
            <label class="app-label">Templates</label>
          </div>
          <div
            v-if="showAutomationApp"
            id="home-automation"
            class="app-group"
            @click="changeActiveSubApp('automation')"
          >
            <VasionHomeAutomation />
            <label class="app-label">Automation</label>
          </div>
          <div
            v-if="showWorkflowApp"
            id="home-workflow"
            class="app-group"
            @click="changeActiveSubApp('workflow')"
          >
            <VasionHomeWorkflow />
            <label class="app-label">Workflow</label>
          </div>
          <div
            v-if="showAdminApp"
            id="home-admin"
            class="app-group"
            @click="changeActiveSubApp('admin')"
          >
            <VasionHomeAdmin />
            <label class="app-label">Admin</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheVasionHome',
  data: function () {
    return {
      appAccessCount: 0,
      inHome: true,
    }
  },
  computed: {
    canAccessSearch() { return this.$store.getters['common/APP_ACCESS_SEARCH'] },
    displayName() { return this.$store.state.common.firstName ? this.$store.state.common.firstName : this.$store.state.common.userName },
    hasAccessToAllForms() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
    hasAccessToMyForms() { return this.$store.getters['common/APP_ACCESS_FORMS'] },
    showAdminApp() { return this.$store.getters['common/showAdminApp'] },
    showAutomationApp() { return this.$store.getters['common/APP_ACCESS_AUTOMATION'] && (this.$store.state.common.userHasSchedulers || this.$store.getters['common/canAccessAutomationApp_RolesOnly']) },
    showCaptureApp() { return this.$store.getters['common/showCaptureApp'] },
    showFormsApp() { return this.$store.getters['common/APP_ACCESS_FORMS'] && (this.userHasForms || this.$store.getters['common/canAccessFormsApp_RolesOnly']) },
    showReportsApp() { return this.$store.getters['common/showReportsApp'] },
    showSignatureApp() { return this.$store.getters['common/showSignatureApp'] },
    showStorageApp() { return this.$store.getters['common/showStorageApp'] },
    showTemplatesApp() { return this.$store.getters['common/showTemplatesApp'] },
    showWorkflowApp() { return this.$store.getters['common/showWorkflowApp'] },
    showWorkspaceApp() { return this.$store.getters['common/showWorkspaceApp'] },
    userHasForms() { return this.$store.state.attributeForm.myForms.length > 0 },
  },
  created() {
    const payload = {
      StartNumber: 1,
      MaxReturnCount: 10000,
      IncludeUserSecurity: false,
      IncludeFields: false,
      PopulateOnlyNameAndID: true,
    }
    this.$store.dispatch('attributeForm/getMyForms', payload)
    this.calculateDivWidth() // Used when refreshing the page
    if (this.appAccessCount == 0 && this.canAccessSearch) {
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', true)
    }
  },
  beforeUpdate() {
    this.calculateDivWidth() // Used when first logging in
  },
  mounted() {
    this.$store.dispatch('mainViews/toggleVasionHome', true)
  },
  methods: {
    calculateDivWidth() {
      this.appAccessCount = 0
      if (this.showWorkspaceApp) this.appAccessCount += 1
      if (this.showReportsApp) this.appAccessCount += 1
      if (this.showStorageApp) this.appAccessCount += 1
      if (this.showCaptureApp) this.appAccessCount += 1
      if (this.showSignatureApp) this.appAccessCount += 1
      if (this.showFormsApp) this.appAccessCount += 1
      if (this.showTemplatesApp) this.appAccessCount += 1
      if (this.showAutomationApp) this.appAccessCount += 1
      if (this.showWorkflowApp) this.appAccessCount += 1
      if (this.showAdminApp) this.appAccessCount += 1
    },
    changeActiveSubApp(appName) {
      this.inHome = false
      this.$store.dispatch('mainViews/changeActiveSubApp', appName)

      let formsRouteName = ''
      if (this.hasAccessToAllForms) {
        formsRouteName = 'AllForms'
      } else if (this.hasAccessToMyForms && !this.hasAccessToAllForms) {
        formsRouteName = 'MyForms'
      }

      switch (appName) {
        case 'admin':
          this.$router.push({ name: 'TheEmptyAdmin' })
          break
        case 'capture':
          this.$router.push({ name: 'StartScan' })
          break
        case 'signature':
          this.$router.push({ name: 'TheSignatureInbox' })
          this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
          break
        case 'reports':
          this.$router.push({ name: 'TheEmptyReport' })
          break
        case 'storage':
          this.$router.push({ name: 'VaultFolders' })
          break
        case 'workspace':
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break;
        case 'forms':
          this.$router.push({ name: formsRouteName })
          break;
        case 'templates':
          this.$store.dispatch('common/getEmailTemplates')
          this.$router.push({ name: 'AllEmailTemplates' })
          this.$store.dispatch('mainViews/changeMainNav', 'all-emails')
          break;
        case 'automation':
          this.$router.push({ name: 'TheAutomationHome' })
          break;
        case 'workflow':
          this.$store.dispatch('workflow/selectWorkflow', 0)
          this.$router.push({ name: 'AllWorkflows' })
          this.$store.dispatch('mainViews/changeMainNav', 'workflow-designer')
          break;
        default:
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .welcome-container {
    height: 100px;
    width: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    justify-content: center;
    background-color: $grey-50;

    .welcome {
      @include Headline;
      height: 30px;
      width: 100%;
      display: inline-block;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .view-profile {
      @include Text;
      color: $orange-300;
      height: 30px;
      width: 100%;
      display: inline-block;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .app-container{
    max-width: 1250px;
    display: flex;
    background-color: $grey-50
  }

  @media(max-width: $tablet){
    .app-container{
      max-width: 500px;
      display: flex;
      background-color: $grey-50
    }
  }

  #home-app-picker {
    @include Text;
    margin: 0 auto;
  }
  .apps-per-row-2 {
    width: 60%;
  }
  .apps-per-row-3 {
    width: 70%;
  }
  .apps-per-row-4 {
    width: 80%;
  }
  .apps-per-row-5 {
    width: 100%;
  }
  .group-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .app-group {
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 250px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 50px;
    margin-bottom: 50px;
  }
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app-label {
    font-size: 24px;
    margin-top: 20px;
    color: $grey-500;
    font-family: $font-family-medium;
  }

  .group-row .app-group:hover {
    background-color: $grey-100;
  }

  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media(max-width: $phone){
    .group-row {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 0px;
    }
  }

  .notLoaded {
    display: none;
  }

</style>
